import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-9.css';

const PageNine = forwardRef((props, ref) => (
  <>
    <SEO title="Page 9" />
    <main className="page page9">
      <div className="page__image-container">
        <StaticImage
          alt="Jimbugs peaking out from Amanda's pocket"
          aspectRatio={3693 / 5449}
          className="page__image page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          placeholder="tracedSVG"
          src="../../images/page-9.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            &ldquo;There&apos;s another way we can go but you&apos;ll have to put me in your pocket.
            Bring something to cover your eyes. You can&apos;t know the way because
            {' '}
            <strong>it is a secret place</strong>
            .&rdquo;
          </p>
          <p>
            Amanda gently put Jimbugs into her pocket and walked into the garden.
          </p>
          <p>
            &ldquo;Remember,&rdquo; said Jimbugs, &ldquo;it&apos;s a secret! No peeking!&rdquo;
          </p>
          <p>
            Amanda had brought one of her Dad&apos;s socks. His feet were really big and it went easily right
            over her whole head.
          </p>
        </div>
      </div>
      <PreviousPage to="/page-8" />
      <NextPage to="/page-10" />
    </main>
  </>
));

const PageNineContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageNine ref={textRef} />;
};

export default PageNineContainer;
